<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
      <div class="two-rows-wrapper">
        <div class="white-container">
          <h5>{{ detailData.title }}</h5>
          <tricolor-line />
          <div class="block-structure" v-for="(item, num) of detailData.groups" :key="'id_'+num" :id="'corruption'+item.title">
            <p class="title_structure">{{item.title}}</p>
            <ul>
              <li v-for="(index, i) of item.files" :key="i" :class="{ 'no-point' : index.is_file }">
                <documents-list v-if="index.is_file"
                                :key="`list${item.id}`"
                                class="documents__list"
                                :doc="{ name: index.title, url: `${ $store.getters.getUrlApi }${ index.link }`, ...index }"
                />
                <a v-else :href="index.link" target="_blank">
                  {{index.title}}<span class="website_img"><span></span></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <aside class="two-rows-navigation">
          <h2>{{ $store.getters.GET_LANG ? 'Навигация по странице' : 'Page navigation' }}</h2>
          <nav>
            <a v-for="(nav, i, index) in detailData.groups" :key="index" ref="goToTitle" :href="`#corruption${nav.title}`">{{ nav.title }}</a>
            <!--          <router-link v-for="(nav, i, index) in anchorNavigation" :key="index" :to="{ path: '/', hash: `nav_${index}` }">{{ nav }}</router-link>-->
          </nav>
        </aside>
      </div>
    </section>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'

export default {
  name: 'AntiCorruptionMeasures',
  data () {
    return {
      navigationLink: [],
      detailData: {}
    }
  },
  methods: {
    async getAllDats () {
      let res = await fetch(`${this.$store.getters.getUrlApi}api/corruption/section/${this.$route.params.id}`, {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      console.log(await res)
      this.detailData = await res.data
      this.navigationLink = [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Противодействие коррупции' : 'Anti-corruption',
          path: '/anti_corruption_measures'
        },
        { title: 'title' }
      ]
      this.navigationLink[2].title = await res.data.title

      if (this.$route.query.title) {
        this.$nextTick(() => {
          this.$refs.goToTitle.map(title => {
            if (title.text === this.$route.query.title) {
              title.click()
            }
          })
        })
      }
    }
  },
  mounted () {
    this.getAllDats()
  },
  watch: {
    '$store.getters.GET_LANG' () {
      this.getAllDats()
    }
  },
  components: {
    BreadCrumb,
    TricolorLine,
    DocumentsList
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: 61.44rem;
}
.no-point {
  list-style-type: none !important;
  margin: 1rem 0 2rem 0 !important;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.875rem;
}
.block-structure{ margin-top: 3.125rem; }
#id_0{ margin-top: 1.875rem;}

.title_structure{
    width: 100%;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1F3245;
    opacity: 0.8;
    padding-bottom: 0.5rem;
}

ul > li{
    list-style-type: disc;
    color: #246CB7;
    margin:  0.75rem 0 0 1rem;
}
a{
    font-size: 0.875rem;
    line-height: 1.38rem;
    color: #246CB7;
    margin-top: 0.75rem;
}
.website_img{
    padding-right: 1.56rem;
    position: relative;
}
.website_img span{
    background: url('../../assets/icons/link_anticorrop.svg') center center no-repeat;
    background-size: .813rem;
    padding: .1rem 0.6rem;
    margin-left: .7rem;
}
@media screen and (max-width: 768px){
    .white-container{
        width: auto;
        margin: 0 .75rem;
        padding: 1.86rem 1.25rem;
    }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    ul > li {
        margin: .95rem 0 0 .76rem;
        font-size: .64rem;
    }
    // .website_img:before {
    //     bottom: .2rem;
    //     top: auto;
    // }
}
</style>
